import React from 'react'

export default function Features(props) {
    let machine = props.machine
    return (
        <div>
        <div id="features" className="machine-features">
        <div>
            <p>Battery <span className="blue">Life</span></p>
            <h4>{machine.runTime}</h4>
          </div>
          <div>
            <p>{machine.model} <span className="blue">Power</span></p>
            <h4>{machine.power}</h4>
          </div>
          <div>
            <p>Brush Down  <span className="blue">Pressure</span></p>
            <h4>
                {machine.brushDownPressure}
            </h4>
          </div>
          <div>
            <p>Vaccum <span className="blue">Power</span></p>
            <h4>{machine.vacuumPower}</h4>
          </div>
        </div>
    </div>
    )
}
