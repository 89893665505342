import React from "react";
// import { graphql } from "gatsby";
// import { Link } from "gatsby";
import Layout from "../components/Layout";
// import styled from "styled-components";
// import { Button } from "../components/common/Button";
// import { AiOutlineFilePdf } from 'react-icons/ai';
import OverviewFeatures from "./OverviewFeatures";
import MachineNavigation from "./MachineNavigation";
import PaddedLayout from '../components/PaddedLayout';
import HeroVideo from '../videos/tomcatherovideo.mp4';
import FeaturedImage from '../images/Carbon_Cut.png';
import DescImage from '../images/Carbon2.jpg';
// import ImageGallery from "../components/productPage/ImageGallery";
// import HeroImage from "../videos/tomcatherovideo.mp4";

//Deck Images
// import DiskDeckImage from "../components/productPage/images/disk-deck-first-frame-play.jpg";
// import DiskDeckAnimate from "../components/productPage/images/disk-deck-loop-v2020.gif";
// import CylindricalDeckImage from "../components/productPage/images/cylindrical-deck-first-frame-play.jpg";
// import CylindricalDeckAnimate from "../components/productPage/images/cylindrical-deck-loop-v2020.gif";
// import OrbitalDeckImage from "../components/productPage/images/orbital-deck-first-frame-play.jpg";
// import OrbitalDeckAnimate from "../components/productPage/images/orbital-deck-animated.gif";
// import DeckCard from "../components/productPage/DeckCard";

//styles

const Products = (props) => {


  let machine = props.pageContext
  console.log(props.pageContext)

  return (
    <Layout>
      {/* Machine Heading */}
      <section className="overview">
        <MachineNavigation machine={machine} page="overview"/>
        {/* Machine Hero */}
        {/* <div className ="machine-downloadable">
          <ul>
            <li><AiOutlineFilePdf className="icon"/>Brochure</li>
            <li><AiOutlineFilePdf className="icon"/>Specifications</li>
            <li><AiOutlineFilePdf className="icon"/>Operators Manual</li>
            <li><AiOutlineFilePdf className="icon"/>Parts Manual</li>
          </ul>
        </div> */}
        
        <div className="machine-hero">
        <video autoPlay muted loop >
          <source src={HeroVideo} type="video/mp4" />
        </video>
        </div>
        

        <PaddedLayout>
          <section className="machine-desc">
          <div>
            <img src={DescImage} alt="Desccriptive Image" />
          </div>
            <div className="machine-description">
            <h1>Tomcat <span className="blue">{machine.model}</span> </h1>
            <p>Example paragraph here. This is just an example paragraph. This paragraph can go on and on and ahve absolutely no meaning. But there will be something ehre meaningful at some point. Example paragraph here. This is just an example paragraph.</p>
            <button>Request Demo</button>
          </div>
          </section>
        </PaddedLayout>
        <OverviewFeatures  machine={machine}/>
        <div className="standard-div">
          <PaddedLayout>
            <h1>Some Featured Headline</h1>
            <p>
              This is some sentence that will guarantee someone will come and buy this product, there is no way they would turn it down.
            </p>
            <button>Click Me</button>
          </PaddedLayout>
          <img src={FeaturedImage} alt="featured_image" />
        </div>
        <PaddedLayout>
        <div className="centered-div">
          <h2>advanced cleaning equipment</h2>
          <p>
            Clean faster and more efficiently wuth the highest performer equipment 
          </p>
        </div>
        <div className="display-items">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        </div>
        </PaddedLayout>


    <div className="machine-demo-reminder">
      <h1>Is the recon right for you?</h1>
      {/* <button className="btn-style-3">Schedule A Demo</button> */}
      <button className="btn-style-3">Find a Dealer</button>
    </div>
    </section>
    </Layout>

  );
};

export default Products;
